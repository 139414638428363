<template>
  <div class="toast-wrap-style">
    <div ref="toastViewer" :style="toastContainerStyle"/>
  </div>
</template>

<script>
/* 토스트 */
import {Editor} from '@toast-ui/editor';

/* 코드 하이라이팅 */
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all';
// 특정 언어만 하이라이팅 할 경우 Step2 에서 해당하는 언어의 파일을 import
// import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
// Step 1. Import prismjs
// import Prism from 'prismjs';
// Step 2. Import language files of prismjs that you need
// import 'prismjs/components/prism-clojure.js';

export default{
  emits:['load'],
  props:{
    height:{
      type: [String, Number],
      default: "100%"
    },
    content:{
      type: String,
      default: "",
    },
    events: Object,
  },
  data(){
    return {
      toastViewer: null,
      /*
      * props로 값이 변경되는 경우가 아니라 setContent 등 직접 값을 변경할 경우
      * Viewer의 content 값을 저장할 곳이 필요
      * */
      contentValue: '',
    }
  },
  watch:{
    /*
    * props 변경 감지를 위해선 Watch 필수
    * */
    content(newValue){
      this.setContent(newValue);
    },
  },
  computed:{
    toastContainerStyle(){
      return{
        height: typeof this.height === 'number' ? `${this.height}%` : this.height,
        textAlign: 'left',
        margin: 'auto',
      }
    }
  },
  methods:{
    getToast(){
      /*
      * wrapping 하지 않은 함수 이용시 호출
      * */
      return this.toastViewer;
    },
    setContent(content){
      this.contentValue = content;
      this.toastViewer.setMarkdown(content)
    },
    getContent(){
      /*
      * toastViewer에 getMarkdown 함수가 없어 새로 구현
      * */
      return this.contentValue;
    }
  },
  mounted(){
    this.toastViewer = new Editor.factory({
      el: this.$refs.toastViewer,
      height: '100%',
      viewer: true,
      plugins: [codeSyntaxHighlight],
      events: {
        load: () => this.$emit('load'),
        /* 로드 말고는 이벤트 작동 확인 실패, */
        change: () => this.$emit('change'),
        caretChange: () => this.$emit('caretChange'),
        focus: () => this.$emit('focus'),
        blur: () => this.$emit('blur'),
      },
      customHTMLRenderer: {
        htmlBlock: {
          iframe(node) {
            return [
              {
                type: 'openTag',
                tagName: 'iframe',
                outerNewLine: true,
                attributes: node.attrs
              },
              {type: 'html', content: node.childrenHTML},
              {type: 'closeTag', tagName: 'iframe', outerNewLine: true}
            ];
          }
        }
      },
    });

    this.setContent(this.content);
  }
}
</script>


<style scoped>
.toast-wrap-style{
  .toastui-editor-contents{
    font-family: 'Pretendard', 'Noto Sans KR', sans-serif;
    font-size: 14px;
    color: #555555;
  }
}
</style>

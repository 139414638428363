import { createApp } from 'vue'
import App from './App.vue'

// import axios from 'axios'

// utils import
import {session, local, cookie} from "@/utils/PStorageUtil";
import {ajax, print, singlePrint, get, post, fileDownload} from "@/utils/PHttpUtil";
import {constants} from "@/utils/PGlobalConstants";
import {cipher} from "@/utils/PCipherUtil";
import {spinner} from "@/components/PSpinner";
import {utils, modal} from "@/utils/PWebUtil";
import {payapp} from "@/utils/payapp";
import {i18n} from "@/constants/locale";
import {paypalObj} from "@/utils/paypal";

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import FloatingVue from 'floating-vue'

// 생성한 뷰 라우터 받아오기
import { router } from './router/router.js'

const app = createApp(App)

app.use(router)  // 라우터 사용
app.use(i18n);
app.use(FloatingVue)

app.config.globalProperties.$local = local
app.config.globalProperties.$session = session
app.config.globalProperties.$cookie = cookie
app.config.globalProperties.$ajax = ajax
app.config.globalProperties.$fileDownload = fileDownload
app.config.globalProperties.$print = print
app.config.globalProperties.$singlePrint = singlePrint
app.config.globalProperties.$httpGet = get
app.config.globalProperties.$httpPost = post
app.config.globalProperties.$globals = constants
app.config.globalProperties.$cipher = cipher
app.config.globalProperties.$modal = modal
app.config.globalProperties.$spinner = spinner
app.config.globalProperties.$utils = utils
app.config.globalProperties.$payapp = payapp
app.config.globalProperties.$paypal = paypalObj
app.config.globalProperties.$i18n = i18n

export const useGlobals = () => app.config.globalProperties

app.mount('#app')
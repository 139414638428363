import {constants as $globals} from "@/utils/PGlobalConstants";

let PAYPAL;
let isAlreadyInit = false;
const paypalObj = {

    init: () => {
        if(isAlreadyInit) return;

        const currentUrl = window.location.href;
        let paypalBaseUrl = "";
        let clientId = "";
        const prodDomain = $globals.prodDomain;

        if(currentUrl.includes(prodDomain)){
            const prodUrl = "https://www.paypal.com/sdk/js?client-id="
            const prodClientId = $globals.paypal.live.clientId;
            paypalBaseUrl = prodUrl;
            clientId = prodClientId;
        }else{
            const sendboxUrl = "https://sandbox.paypal.com/sdk/js?client-id="
            const sendboxClientId = $globals.paypal.sendbox.clientId;
            paypalBaseUrl = sendboxUrl;
            clientId = sendboxClientId;
        }

        const paypalScript = `${paypalBaseUrl}${clientId}`
        const script = document.createElement('script');
        script.setAttribute('src', paypalScript);
        document.head.appendChild(script);
        script.onload = () => {
            PAYPAL = window.paypal;
            isAlreadyInit = true;
        }
    },

    render: (renderSelector, options) => {
        const defaultOptions = {
            style: {
                color: 'gold',
                layout: 'horizontal',
                shape: 'rect',
                label: 'paypal', // 'checkout','buynow', 'pay', 'installment'
                tagline: false,
                height: 50, //25 ~ 55
                borderRadius: 10,
            }
        }

        const applyOption = {
            ...defaultOptions, ...options
        }

        PAYPAL.Buttons(applyOption).render(renderSelector)
    },


    /**
     * @param params
     * @param params.goodName
     * @param params.price
     * @param params.useDepositPoint
     * @param params.useRewardPoint
     * @param params.outboundReqDate
     * @param params.userTelNo
     * @param params.payInfos[] {paymentBoxId, couponId, userTelNo, countryTelCd}
     * }
     * */
    payForDelivery: (params) => {
    },

    payForDeposit: ({price, userCountryTelCd, userTelNo, returnUrlQuery ='', isVbank}) => {
    }

}

export { paypalObj }